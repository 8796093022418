import React from "react";
import theme from "theme";
import { Theme, Text, Button, Box, Section, Hr, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Home | FootBallTech
			</title>
			<meta name={"description"} content={"FootBallTech offers eco-conscious football training equipment designed for both gym and court sessions. Explore our range of sustainably-made products, from training cones to precision pass walls"} />
			<meta property={"og:title"} content={"Home | FootBallTech"} />
			<meta property={"og:description"} content={"FootBallTech offers eco-conscious football training equipment designed for both gym and court sessions. Explore our range of sustainably-made products, from training cones to precision pass walls"} />
			<meta property={"og:image"} content={"https://footinv-uk.net/img/equip_6.jpg"} />
			<link rel={"shortcut icon"} href={"https://footinv-uk.net/img/icon37.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://footinv-uk.net/img/icon37.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://footinv-uk.net/img/icon37.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://footinv-uk.net/img/icon37.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://footinv-uk.net/img/icon37.png"} />
			<meta name={"msapplication-TileImage"} content={"https://footinv-uk.net/img/icon37.png"} />
			<meta name={"msapplication-TileColor"} content={"https://footinv-uk.net/img/icon37.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="100px 0 0 0" quarkly-title="Hero-17" transition="background-color 0.2s ease 0s" background="rgba(0, 0, 0, 0) url(https://footinv-uk.net/img/Group%2010.png) 0% 100% /contain no-repeat scroll padding-box">
			<Override slot="SectionContent" flex-direction="row" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				justify-content="space-around"
				lg-flex-direction="column"
				flex-direction="column"
			>
				<Box
					min-width="100px"
					min-height="100px"
					lg-width="100%"
					display="flex"
					flex-direction="column"
					lg-padding="0px 0 0px 0px"
					lg-margin="0px 0px 50px 0px"
					margin="0px 0px 80px 0px"
					align-items="center"
					padding="0px 0px 100px 0px"
				>
					<Text
						margin="0px 0px 20px 0px"
						font="normal 700 64px/1.2 --fontFamily-sans"
						sm-font="normal 700 42px/1.2 --fontFamily-sans"
						padding="0px 250px 0px 250px"
						text-align="center"
						lg-padding="0px 0 0px 0"
					>
						FootBallTech:{" "}
						<br />
						Master Your Craft
					</Text>
					<Text
						margin="0px 0px 50px 0px"
						font="--lead"
						color="#8b9197"
						text-align="center"
						padding="0px 90px 0px 90px"
						lg-padding="0px 0 0px 0"
						lg-margin="0px 0px 30px 0px"
					>
						FootBallTech offers eco-conscious football training equipment designed for both gym and court sessions. Explore our range of sustainably-made products, from training cones to precision pass walls, and redefine the way you train. Dive deep into a world where tradition meets innovation.
					</Text>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						sm-flex-direction="column"
						sm-align-items="center"
						sm-align-self="stretch"
					>
						<Button
							margin="0px 15px 0px 0px"
							padding="12px 28px 12px 28px"
							background="--color-green"
							border-radius="8px"
							font="normal 400 17px/1.5 --fontFamily-sans"
							sm-margin="0px 0 15px 0px"
							border-color="--color-green"
							hover-color="--darkL1"
							hover-background="rgba(82, 46, 224, 0)"
							sm-width="100%"
							type="link"
							text-decoration-line="initial"
							href="/contact-us"
							sm-text-align="center"
							sm-max-width="200px"
							sm-align-items="center"
							sm-display="flex"
							sm-justify-content="center"
							border-style="solid"
							border-width="2px"
						>
							Visit Shop
						</Button>
						<Button
							margin="0px 0 0px 0px"
							padding="12px 28px 12px 28px"
							background="rgba(0, 119, 204, 0)"
							color="--darkL1"
							font="normal 400 17px/1.5 --fontFamily-sans"
							sm-width="100%"
							type="link"
							href="/about"
							sm-text-align="center"
							sm-max-width="200px"
							sm-display="flex"
							sm-align-items="center"
							sm-justify-content="center"
						>
							About Us
						</Button>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 90px 0" quarkly-title="Product-6">
			<Override slot="SectionContent" lg-align-items="center" />
			<Text
				margin="0px 0px 20px 0px"
				font="normal 500 56px/1.2 --fontFamily-sans"
				text-align="center"
				lg-width="70%"
				md-width="100%"
				color="--darkL1"
			>
				Featured Products 🥅
			</Text>
			<Text margin="0px 0px 30px 0px" font="normal 300 22px/1.5 --fontFamily-serifGaramond" text-align="center">
				Discover the blend of tradition and innovation. At FootBallTech, we understand the nuances of football. Our equipment is crafted to elevate your training for both gyms and courts.
			</Text>
			<Hr
				min-height="10px"
				margin="0px 0px 50px 0px"
				border-color="--color-darkL1"
				width="40px"
				border-width="2px 0 0 0"
				align-self="center"
			/>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				md-grid-template-columns="1fr"
				md-grid-gap="40px 0"
				md-margin="0px 0px 50px 0px"
				grid-gap="0 35px"
				lg-grid-gap="16px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					background="#d9e9fa"
					border-radius="4px"
				>
					<Box
						min-width="100px"
						min-height="100px"
						padding="30px 30px 30px 30px"
						border-color="--color-darkL1"
						border-radius="0 0 4px 4px"
						display="flex"
						flex-direction="column"
						lg-padding="20px 20px 20px 20px"
						flex="1 1 0%"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 20px/1.2 --fontFamily-sans">
							Eco-Tech Training Cones
						</Text>
						<Text margin="0px 0px 25px 0px" font="normal 300 16px/1.5 --fontFamily-serifGaramond" lg-margin="0px 0px 18px 0px" flex="1 1 0%">
							Built from sustainable materials, these durable cones are designed for endless hours of drills and practice. Their weighted base ensures they stay grounded even in windy conditions.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					background="#d9e9fa"
					border-radius="4px"
					display="flex"
					flex-direction="column"
				>
					<Box
						min-width="100px"
						min-height="100px"
						padding="30px 30px 30px 30px"
						border-color="--color-darkL1"
						border-radius="0 0 4px 4px"
						lg-padding="20px 20px 20px 20px"
						flex="1 1 0%"
						display="flex"
						flex-direction="column"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 20px/1.2 --fontFamily-sans">
							Precision Pass Wall
						</Text>
						<Text margin="0px 0px 25px 0px" font="normal 300 16px/1.5 --fontFamily-serifGaramond" lg-margin="0px 0px 18px 0px" flex="1 1 0%">
							Improve your passing and shooting accuracy with our solid wooden rebound wall. Marked with target zones, it's your perfect partner for mastering technique.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					background="#d9e9fa"
					border-radius="4px"
					display="flex"
					flex-direction="column"
				>
					<Box
						min-width="100px"
						min-height="100px"
						padding="30px 30px 30px 30px"
						border-color="--color-darkL1"
						border-radius="0 0 4px 4px"
						lg-padding="20px 20px 20px 20px"
						flex="1 1 0%"
						display="flex"
						flex-direction="column"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 20px/1.2 --fontFamily-sans">
							FootBallTech's Dynamic Goal Target Net
						</Text>
						<Text margin="0px 0px 25px 0px" font="normal 300 16px/1.5 --fontFamily-serifGaramond" lg-margin="0px 0px 18px 0px" flex="1 1 0%">
							Elevate your shooting prowess! This goal net features adjustable target pockets, allowing players to practice hitting specific areas with precision.
						</Text>
					</Box>
				</Box>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				margin="30px 0px 30px 0px"
				grid-template-columns="repeat(2, 1fr)"
				md-flex-direction="column"
			>
				<Box
					min-width="100px"
					min-height="100px"
					width="32.5%"
					lg-width="35%"
					background="#ffdd61"
					border-radius="4px"
					md-width="100%"
					md-margin="0px 0px 35px 0px"
					display="flex"
					flex-direction="column"
				>
					<Box
						min-width="100px"
						min-height="100px"
						padding="30px 30px 30px 30px"
						background="#ffdd61"
						border-color="--color-darkL1"
						border-radius="0 0 4px 4px"
						lg-padding="20px 20px 20px 20px"
						display="flex"
						flex-direction="column"
						flex="1 1 0%"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 20px/1.2 --fontFamily-sans">
							Our Commitment 🌍
						</Text>
						<Text margin="0px 0px 25px 0px" font="normal 300 16px/1.5 --fontFamily-serifGaramond" lg-margin="0px 0px 18px 0px" flex="1 1 0%">
							Embracing sustainability, FootBallTech is dedicated to reducing the environmental impact of football equipment. We use eco-friendly materials, ensuring the future of our planet is as bright as the future of your football career.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					width="70%"
					display="flex"
					align-items="center"
					flex-direction="column"
					justify-content="center"
					padding="0px 80px 0px 80px"
					lg-width="65%"
					md-width="100%"
					md-margin="0px 0px 15px 0px"
					md-padding="0px 0 0px 0"
					lg-padding="0px 40px 0px 40px"
				>
					<Text margin="0px 0px 0px 0px" font="normal 300 22px/1.5 --fontFamily-serifGaramond" text-align="center">
						For the most up-to-date product lineup and pricing details, please reach out to us via email. We'll promptly provide you with all the relevant information to ensure you have the best shopping experience with FootBallTech.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-3">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				display="flex"
				width="65%"
				align-items="flex-start"
				sm-margin="0px 0px 30px 0px"
				justify-content="center"
				lg-width="100%"
			>
				<Box
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					width="100%"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					height="auto"
					padding="0px 0px 70% 0px"
				>
					<Image
						bottom={0}
						min-height="100%"
						src="https://footinv-uk.net/img/ball_4.jpg"
						object-fit="cover"
						left={0}
						top={0}
						right={0}
						position="absolute"
						display="block"
						width="100%"
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				lg-order="1"
				width="35%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
			>
				<Box
					transform="translateY(0px)"
					width="100%"
					overflow-y="hidden"
					position="relative"
					hover-transform="translateY(-10px)"
					padding="0px 0px 65% 0px"
					height="auto"
					overflow-x="hidden"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						bottom={0}
						src="https://footinv-uk.net/img/training_2.jpg"
						width="100%"
						left={0}
						right={0}
						min-height="100%"
						object-fit="cover"
						position="absolute"
						display="block"
						top={0}
					/>
				</Box>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Discover the FootBallTech difference today and redefine the way you train. 🌟
				</Text>
			</Box>
		</Section>
		<Section padding="70px 0 70px 0" sm-padding="60px 0 60px 0">
			<Box
				padding="72px 72px 72px 72px"
				background="--color-green"
				border-radius="24px"
				margin="0px 0px 64px 0px"
				sm-padding="36px 36px 36px 36px"
				color="--light"
			>
				<Box
					width="80%"
					display="flex"
					flex-direction="column"
					md-width="100%"
					md-align-items="center"
					md-justify-content="center"
					md-text-align="center"
					lg-width="100%"
				>
					<Text
						margin="0px 0px 16px 0px"
						font="--headline2"
						color="--light"
						md-text-align="center"
						sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					>
						Shipping & Handling 🚀
					</Text>
					<Text margin="0px 0px 0px 0px" font="--base" color="--greyD3" md-text-align="center">
						At FootBallTech, we aim to ensure that your order reaches you as swiftly and safely as possible. Our dedicated team works around the clock to process and ship your order efficiently. For detailed shipping calculations, delivery estimates, or specific shipping queries, please send us an email at contact@footinv-uk.net. We're always happy to assist!
					</Text>
					<Text margin="20px 0px 0px 0px" font="--base" color="--greyD3" md-text-align="center">
						Discover the FootBallTech difference today and redefine the way you train. 🌟
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});